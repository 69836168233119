<template>
  <StoresFetcher>
    <template #default="{ data: stores }">
      <b-alert
        show
        variant="warning"
        class="text-center w-100 mt-0 p-0 z-index-1"
      >
        <b-dropdown variant="clear">
          <span
            slot="button-content"
            class="d-inline-flex flex-wrap align-items-center"
          >
            <span class="fas fa-university mr-1" />
            <span class="text-bold mr-1">{{ currentStoreName }}</span>
            <small>
              <em>(clique para alterar)</em>
            </small>
          </span>

          <template v-if="filterStores(stores).length > 0">
            <b-dropdown-item
              v-for="store in filterStores(stores)"
              :key="store.id"
              :disabled="isCurrent(store)"
              @click="setCurrentStore(store)"
            >
              {{ store.name }}
            </b-dropdown-item>
          </template>

          <b-dropdown-item v-else disabled>
            Você não tem acesso a nenhuma outra loja
          </b-dropdown-item>
        </b-dropdown>
      </b-alert>
    </template>
  </StoresFetcher>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import StoresFetcher from './StoresFetcher'

export default {
  name: 'StoreSwitcher',
  components: { StoresFetcher },
  computed: {
    ...mapState('store', ['currentStore']),
    currentStoreName() {
      return this.currentStore?.name ?? ''
    }
  },
  methods: {
    ...mapActions('store', ['setCurrentStore']),
    isCurrent(store) {
      return this.currentStore?.id === store.id
    },
    filterStores(stores) {
      return stores.filter(({ id }) => id !== this.currentStore?.id)
    }
  }
}
</script>
